import { Component, OnInit } from '@angular/core';
import { MatDialog, PageEvent } from '@angular/material';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { AuthService } from 'src/utils/auth.service';
import { MatSort, Sort } from '@angular/material/sort';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { colorConfig } from 'src/environments/color-configs';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  showTable: boolean = false;
  displayCols = ['phoneNumber', 'role', 'username', 'email', 'lastLoginDateTime', 'actions'];
  searchName = '';
  searchUrl = '';
  usersArray = [];
  orgId = '';
  sortUrl = '';
  totalRecords = 0;
  length = this.totalRecords;
  pageSize = 10;
  rowEven = 'row-orange';
  userLimit:number;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  noRecords = false;
  urlOrigin = '';
  searchBg = 'rgb(221, 92, 15)';
  buttonTextColor = 'black';
  userPoolDetails: any = {}
  tokenValue: any = {};
  isMaster: boolean = false;
  role: string = '';

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, private dialog: MatDialog, private datepipe: DatePipe) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
    this.urlOrigin = window.location.origin;
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
      this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        this.role = this.apiService.getLocalStorage('role')
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
        this.role = this.apiService.getLocalStorage('role')
      }
    });
    this.getOrgDetails();
  }
  searchChange(event) {
    console.log(event)
    if (event !== '') {
      this.searchUrl = '&name=' + event;
    } else {
      this.searchUrl = '';
    }
    this.defaultPage = 0;
    this.getUsers();
  }

  async getUsers() {
    // var url = 'truckType?orgId=' + this.orgId;
    var url = 'user?orgId='+this.orgId;
    this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, this.defaultRows).subscribe((data: any) => {
      this.getUserPoolData();
      this.usersArray = [];
      this.usersArray = data['users'].filter(user => {
        return (!user.username.toLowerCase().includes('steven') && !user.username.toLowerCase().includes('admin'))
      });
      this.totalRecords = this.usersArray.length;
      if (this.totalRecords > 0) {
        this.showTable = true;
        this.noRecords = false;
      } else {
        this.noRecords = true;
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getUserPoolData() {
    this.userPoolDetails = {};
    var poolData = JSON.parse(atob(this.apiService.getLocalStorage('poolData')));
    var userpool = this.apiService.getLocalStorage('userPool');
    var url = 'createCognitoUserPool?userPoolId='+poolData[userpool]['userPoolId'];
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.userPoolDetails = data;
      this.userPoolDetails['poolData'] = poolData;
      this.userPoolDetails['userPool'] = userpool;
    }, (error) => {
      console.log(error);
    })
  }
  sortData(sort: Sort) {
    console.log(sort);
    this.searchUrl = '';
    if (sort.active !== 'actions') {
      if (sort.direction) {
        var key = sort.active;
        if (sort.active === "Sensor One") { key = "Sensor1Id" };
        if (sort.active === "Sensor Two") { key = "Sensor2Id" };
        if (sort.active === "Portal") { key = "PortalId" };
        this.sortUrl = "sortBy=" + key + "&sortType=" + sort.direction;
        this.getUsers();
      } else {
        this.sortUrl = '';
        this.getUsers();
      }
    }
  }
  
  handlePage(event: PageEvent) {
    console.log(event);
    this.defaultPage = event.pageIndex;
    this.defaultRows = event.pageSize;
    this.getUsers();
  }
  getHeader(header){
    var column = header;
      column = header === 'email'?"E-mail":header === 'phoneNumber'?"Cell Number":header === 'lastLoginDateTime'?"Last Login Date Time":header;
      return column
  }
  addUser(userData, getType: any,sendEmail:boolean) {
    if(this.totalRecords >= this.userLimit){
      this.showLimitAlert();
    }else {
    var title = getType === 'userPost' ? 'Add User' : 'Update User';

    const modalData = {};
    modalData['editData'] = {};
    if (getType === 'userPatch') {
      modalData['editData'] = userData;
    }
    modalData['sendEmail'] = sendEmail;
    modalData['userPoolData'] = this.userPoolDetails;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: ' ',
        data: modalData,
        orgId: this.orgId,
        type: getType
      },
      minWidth: 60
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.confirmUserOperations(result)
      }
    });
  }
  }
  async confirmUserOperations(result) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm user',
        message: 'User '+result['type']+' successfully.: \n\n ' + result['username']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      this.getUsers();
    });
  }
  async showLimitAlert() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'You cann\'t create new user.',
        message: 'You have reached your limit. \n\n ' +'Allowed users - '+ this.userLimit
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      // this.getUsers();
    });
  }
  getTableData(col,value){
    if(col === 'lastLoginDateTime'){
      var time = this.datepipe.transform(value, 'yyyy/MM/dd hh:mm a')
      return time
    }else if(col === 'phoneNumber'){
      var str = value; 
    var re = /\+1/gi; 
    // Use of String replace() Method
    var newstr = str.replace(re, ""); 
    return newstr
    }else {
      return value
    }
  }
  async deleteData(userObj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this User?: \n\n ' + userObj['email']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delDoc(userObj['username'])
      }
    });
  }
  async resendInvite(userObj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Resend Invite',
        message: 'Are you sure you want to resend invite to this User?: \n\n ' + userObj['username']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.sendInvite(userObj)
      }
    });
  }
  async sendInvite(getobj) {
    let url = 'resendInvite?username='+getobj['username']+'&url='+this.urlOrigin;
    await this.apiService.getByUrl(url).subscribe(
      (res) => {
        console.log(res);
        this.helper.openSnackBar("Invite sent again to user's registered email.","Ok")
        this.getUsers();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

  async delDoc(getId) {
    let url = 'user?username=' + getId;
    await this.apiService.deleteData(url).subscribe(
      (res) => {
        this.getUsers();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      if(data){
        this.userLimit = data.organization['NumberOfUsers']
      }
      this.getUsers();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
}
