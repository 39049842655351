import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';

@Component({
  selector: 'app-management-landing-page',
  templateUrl: './management-landing-page.component.html',
  styleUrls: ['./management-landing-page.component.scss']
})
export class ManagementLandingPageComponent implements OnInit {
  userpoolData: any = [];
  filteredData: any = [];
  orgTokensOnStorage: any = {};
  isMaster: boolean = false;
  searchDescription: any = '';

  constructor(private helper: HelperService, private apiService: ApiService, private router: Router) { }

  ngOnInit() {
    var pooldata = JSON.parse(atob(this.apiService.getLocalStorage('poolData')));
    if(this.apiService.getLocalStorage('role') === 'Master'){
      this.isMaster = true;
    }
    for(let pool in pooldata){
      pooldata[pool].orgId = Number(pooldata[pool].orgId)
      this.userpoolData.push(pooldata[pool])
    }
    this.userpoolData.sort((a, b) => a.orgId - b.orgId);
    this.filteredData = this.userpoolData;
    // this.getOrgLoginToken();
  }
  isAuthenticated() {
    let res = this.helper.isAuthenticated();
    return this.helper.isAuthenticated();
    // return true;
  }
  async getOrgLoginToken(getorgId, getUrl){
    this.helper.openSnackBar("Authenticating...", "OK")
    var url = 'userLogin';
    var orgdata = {
      "masterUsername":this.apiService.getLocalStorage('username'),
      "password":"SNOW$02660$ma$",
      "orgId":Number(getorgId)
    }
    var payload = {
      'input': btoa(JSON.stringify(orgdata))
    }
    var existingOrgIds = this.apiService.getLocalStorage('masterTokens');
    this.orgTokensOnStorage = existingOrgIds?JSON.parse(existingOrgIds):{};
    await this.apiService.postNewdata(url, payload).subscribe(
      (res: any) => {
        console.log(res);
        var org = {}
        this.orgTokensOnStorage[getorgId]=res.token;
        this.apiService.addLocalStorage('masterTokens',JSON.stringify(this.orgTokensOnStorage))
        // this.router.navigate([getUrl], { queryParams: { orgId: getorgId }});
        const urlOrigin = window.location.origin;
        var path = urlOrigin+getUrl+'?manageOrg='+getorgId;
        window.open(path, '_blank');
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  goto(orgId, getUrl){
    this.router.navigate([getUrl], 
      { queryParams: { 'manageOrg': orgId }});
  }
  filterTable(): void {
    this.filteredData = this.userpoolData.filter(item => {
      const matchesName = this.searchDescription
        ? (item.orgName.toLowerCase().includes(this.searchDescription.toLowerCase()) || JSON.stringify(item.orgId).includes(this.searchDescription))
        : true;
      return matchesName;
    });
  }
  getImage(orgid){
    var env = window.location.origin.includes('dev')?'spmapskmzdevva':'spmapskmz';
    var imageUrl = 'https://'+env+'.s3.us-east-1.amazonaws.com/Logos/Logo'+orgid+'.png'
    return imageUrl
  }
}
