import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { AuthService } from 'src/utils/auth.service';
import { MatSort, Sort } from '@angular/material/sort';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-truck-types',
  templateUrl: './truck-types.component.html',
  styleUrls: ['./truck-types.component.scss']
})
export class TruckTypesComponent implements OnInit {
  showTable: boolean = false;
  noRecords: boolean = false;
  trucksTypesArray = [];
  displayCols = ['Name', 'Sensor One', 'Sensor Two', 'Portal', 'actions'];
  orgId = '';
  searchUrl = '';
  sortUrl = '';
  description = '';
  searchName = '';
  // currentRow = 10;
  totalRecords = 0;
  length = this.totalRecords;
  rowEven = 'row-orange';
  pageSize = 10;
  pageEvent: PageEvent;
  defaultRows: any = this.pageSize;
  defaultPage: any = 0;
  pageSizeOptions: number[] = [10, 25, 50, 100, 200];
  thBg = '#e9ba9e';
  domain = 'snowpaths';
  sensors=[];
  searchBg = 'rgb(221, 92, 15)';
  buttonTextColor = 'black';
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private apiService: ApiService, private helper: HelperService,
    private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
      this.thBg = colorConfig[this.domain]['thBackground'];
      this.rowEven = colorConfig[this.domain]['reports']['tableRowEvenClass'];
      this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.sortUrl='sortType=asc&sortBy=Name';
    this.getTruckTypes();
  }
  async getTruckTypes() {
    var url = 'truckType?orgId=' + this.orgId;
    this.apiService.getSearchwithPagination(url, this.searchUrl, this.sortUrl, this.defaultPage, this.defaultRows).subscribe((data: any) => {
      this.trucksTypesArray = data['truckTypes'];
      this.totalRecords = data.count;
      this.getSensorTypes();
      if (this.totalRecords > 0) {
        this.showTable = true;
        this.noRecords = false;
      } else {
        this.noRecords = true;
      }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  handlePage(event: PageEvent) {
    console.log(event);
    this.defaultPage = event.pageIndex;
    this.defaultRows = event.pageSize;
    this.getTruckTypes();
  }
  editData(editobj) {

  }
  async deleteData(truckObj) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you want to delete this vehicle type?: \n\n ' + truckObj['Name']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delDoc(truckObj['Id'])
      }
    });
  }
  async delDoc(getId) {
    let url = 'truckType?truckTypeId=' + getId;
    await this.apiService.deleteData(url).subscribe(
      (res) => {
        this.getTruckTypes();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

  addTruckType(truckData, getType: any) {
    var title = getType === 'post' ? 'Add Vehicle Type' : 'Update Vehicle Type';

    const modalData = {};
    modalData['editData'] = {};
    if (getType === 'patch') {
      modalData['editData'] = truckData;
    }
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: ' ',
        data: modalData,
        orgId: this.orgId,
        type: getType
      },
      minWidth: 60
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.getTruckTypes();
      }
    });
  }
  searchChange(event) {
    console.log(event)
    if (event !== '') {
      this.searchUrl = '&name=' + event;
    } else {
      this.searchUrl = '';
    }
    this.defaultPage = 0;
    this.getTruckTypes();
  }
  sortData(sort: Sort) {
    console.log(sort);
    this.searchUrl = '';
    if (sort.active !== 'actions') {
      if (sort.direction) {
        var key = sort.active;
        if (sort.active === "Sensor One") { key = "Sensor1Id" };
        if (sort.active === "Sensor Two") { key = "Sensor2Id" };
        if (sort.active === "Portal") { key = "PortalId" };
        this.sortUrl = "sortBy=" + key + "&sortType=" + sort.direction;
        this.getTruckTypes();
      } else {
        this.sortUrl = '';
        this.getTruckTypes();
      }
    }
  }
  getDescription(id: number, list: any, ddKey: any, ddValue: any) {
    var selectObj = list.find((obj: any) => {
      return obj[ddValue] === Number(id)
    })
    return selectObj && selectObj[ddKey] ? selectObj[ddKey] : '';
  }
  async getSensorTypes() {
    var url = 'networkFleetSensor';
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.sensors = res;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

}
