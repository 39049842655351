import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';

@Component({
  selector: 'app-public-map-settings',
  templateUrl: './public-map-settings.component.html',
  styleUrls: ['./public-map-settings.component.scss']
})
export class PublicMapSettingsComponent implements OnInit {
  showPage=false;
  saveBg = 'rgb(241, 119, 46)';
  domain = 'snowpaths';
  authResult:any;
  apiType = 'post';
  orgId='';
  allPortals: any;
  publicMapData= {
    Message:''
  };
  message='';
  selectedPortal: any;
  mapSettings:any;
  logo = '../../../assets/logos/SnowPaths-logo.png'
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private authService: AuthService, private apiService: ApiService,
    private helper: HelperService,private dialog : MatDialog) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
    // if(this.domain === 'streetpaths'){
    //   this.saveBg = colorConfig[this.domain]['streetPriority']['saveBackground'];
    //   this.logo = '../../../assets/logos/StreetPathsLogo.png'
    // }
    // if(this.domain === 'fleetpaths'){
    // }
    this.logo = '../../../assets/logos/'+colorConfig[this.domain]['logoName']+'.png'
    this.saveBg = colorConfig[this.domain]['streetPriority']['saveBackground'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.getPortals();
    this.getMapDetails();
  }
  async getMapDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.mapSettings = data;
    }, (error) => {
      console.log(error);
    })
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.allPortals[0]['Id'];
        this.getPublicMapSettings()
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getportalId(portalId) {
    this.showPage = false;
    console.log(portalId);
    this.selectedPortal = portalId;
    this.getPublicMapSettings();
  }
  async getPublicMapSettings(){
    this.apiType = 'post';
    this.publicMapData.Message='';
    var url = 'publicMapMsg?orgId=' + this.orgId+'&portalId='+this.selectedPortal;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        if(res['publicMapMsgs'][0]){
          this.apiType = 'patch';
          this.publicMapData = res['publicMapMsgs'][0];
        }else {
          this.apiType = 'post';
        }
        this.showPage=true;
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  save(){
    if(this.apiType === 'post'){
      this.createPublicMapSetting();
    }else{
      this.updatePublicMapSetting();
    }
  }
  async createPublicMapSetting() {
    var url = 'publicMapMsg';
    var payload = {
      "Message": this.publicMapData.Message,
      "OrganizationId": this.orgId,
      "PortalId": this.selectedPortal
    }
    await this.apiService.postNewdata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.getPublicMapSettings();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async updatePublicMapSetting() {
    var url = 'publicMapMsg?publicMapMsgId='+this.publicMapData['Id'];
    var payload = {
      "Message": this.publicMapData.Message,
    }
    await this.apiService.updatedata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.getPublicMapSettings();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }

}
