import { AgmInfoWindow, ControlPosition, MouseEvent } from '@agm/core';
import { ChangeDetectorRef, Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { AgmDrawingManager} from '@agm/drawing'

import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { colorConfig } from 'src/environments/color-configs';
import { AuthService } from 'src/utils/auth.service';
import { DatePipe } from '@angular/common';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-service-control',
  templateUrl: './service-control.component.html',
  styleUrls: ['./service-control.component.scss']
})
export class ServiceControlComponent implements OnInit {
  @ViewChild('infoWindow', { static: false }) infoWindowsView: AgmInfoWindow;
  isLoading = true;
  showMap = false;
  drawControls = true;
  message = "Loading, Please wait a while..."
  bgColor = 'rgb(253, 235, 224)';
  mapClass = 'map-container-60';
  endClass = 'end-container-34';
  domain = 'snowpaths';
  lat = 41.101721;
  lng = -80.643667;
  zoom = 0;
  mapType = 'roadmap'; //roadmap or satellite
  public customStyle = [{  
    "featureType": "poi",  
    "stylers": [{  
        visibility: "off",  
    }]  
  }, ];
  authResult: any;
  orgId: any = 0;
  userEmail: string = '';
  boundaryUrl: any;
  appVersion: number = 0;
  pilotMode: number = 0;
  GIS: number = 1;
  selectedPortal: any = 0;
  searchBg = 'rgb(221, 92, 15)';
  labelColor = 'black';
  allPortals: any;
  urlOrigin: string = '';
  managerOptions = {
    drawingControl: true,
    drawingControlOptions: {
      position: ControlPosition.TOP_CENTER,
      drawingModes: []
    },
    polygonOptions: {
      draggable: true,
      editable: true
    },
    drawingMode: "polygon"
  };
  serviceList: any = []
  newServiceList: any = []
  ProblemTypes: any = [];
  // ['Water Break','Power Cut','Others']
  lastVisitedPortal: any = '';
  userData: any = {};
  markers: any;
  dropdownSettings: IDropdownSettings = {};
  eventPortals: any  [];
  selectedProblemType: any = '';
  tokenValue: any = {};
  isMaster: boolean = false;

  constructor(private apiService: ApiService, private authService: AuthService, private cdr: ChangeDetectorRef,
    private router: Router, private helper: HelperService, private dialog: MatDialog, private drawingManager: AgmDrawingManager,
    private datepipe: DatePipe) {
      const urlOrigin = window.location.origin;
    this.urlOrigin = window.location.origin;
    let isAvailable = urlOrigin.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if(isFleetpathAvailable){
      this.domain='fleetpaths';
    }
    if(isAvailable){
      this.domain='streetpaths';
    }
    this.handlePortalStyles();
    this.managerOptions.drawingControlOptions.drawingModes.push('marker')
     }

  ngOnInit() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = Number(this.tokenValue['custom:organizationid']);
        this.userEmail = this.tokenValue['email']
        this.getUserDetails();
      } else {
        this.router.navigate[('/')]
      }
    });
    if (!this.authResult) {
      localStorage.clear();
      this.router.navigate[('/')]
    }
  }
  handlePortalStyles(){
    this.domain=this.apiService.getLocalStorage('domain');
    this.bgColor = colorConfig[this.domain]['reports']['background'];
    this.searchBg = colorConfig[this.domain]['reports']['searchBg'];
    this.labelColor = colorConfig[this.domain]['reports']['labelColor'];
  }
  async getOrgDetails() {
    var url = 'organization?orgId=' + this.orgId;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.boundaryUrl = data.boundaryUrl;
      this.appVersion = data.organization['Lite'];
      this.pilotMode = data.organization['Pilot'];
      this.GIS = data.organization['GIS'];
      this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      // this.showMap = true;
      this.getPortals();
      // this.getPriorityData();
      
      
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPortals() {
    this.dropdownSettings = {
      idField: 'Id',
      textField: 'Name',
      enableCheckAll: false,
      allowSearchFilter: true
  };
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        if(this.lastVisitedPortal && this.lastVisitedPortal !== undefined){
          this.selectedPortal = this.lastVisitedPortal;
        }else {
          this.selectedPortal = this.allPortals[0]['Id'];
        }
        this.getproblemTypes();
        
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  getServicesData(url){ 
    this.serviceList = [];
    // var url = 'siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal;
    this.apiService.getMapDetails(url).subscribe((data: any) => {
      this.serviceList = data;
      this.serviceList.forEach(service => {
        // service.PortalId = JSON.parse(service.PortalId);
        service.Portal = [];
        this.allPortals.forEach(portal => {
          if(service.PortalId.length > 0 && service.PortalId.includes(portal.Id)){
            service.Portal.push(portal)
          }
        })
        if(service.StartDate !== null){
          service.StartDate = new Date(service.StartDate)
          }
          if(service.EndDate !== null){
            service.EndDate = new Date(service.EndDate)
            }
        return service
      });
      this.isLoading = false;
        this.showMap = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
    // this.apiService.addLocalStorage('mapZoom',this.zoom)
  }
  markerComplete(event: MouseEvent) {
    console.log(event)
  }
  onMapClick(event) {
    if(this.newServiceList && this.newServiceList[0] && this.newServiceList[0]['Id']){
      this.helper.openQuickSnackBar("Please update pending event details & retry new event.","Ok")
    }else {
      this.markers = event;
      var newProblem = {
        "Latitude":event.coords.lat,
        "Longitude":event.coords.lng,
        "OrgId":Number(this.orgId),
        "PortalId":[],
        "Portal": [],
        "Title":"",
        "ProblemType":"",
        "Details":"",
        "StartDate":null, //2024-05-03 00:34:00.000000 format
        "EndDate":null,
        "SiteAddress":"",
        "PinColor": "#FF0000",
        "PinTextColor": "#000000"
        }
      this.newServiceList.push(newProblem)
      this.getAddressofPoint(newProblem)
      // this.openInfoWindow()
    }
  }
  getAddressofPoint(problem, index?){
    var newProblem = problem;
    this.apiService.showLoader();
    console.log(newProblem.Latitude+','+newProblem.Longitude)
    var url = 'https://nominatim.openstreetmap.org/reverse?lat='+newProblem.Latitude+'&lon='+newProblem.Longitude+'&format=json';
    this.apiService.getByExternalUrl(url).subscribe((data: any) => {
      if(data && data.display_name){
        if(index){
          this.newServiceList[index]['SiteAddress'] = data.display_name;
        }else{
          newProblem['SiteAddress'] = data.display_name;
        }
      }
      // this.newServiceList.push(newProblem)
    this.apiService.hideLoader();
    }, (error) => {
      console.log(error);
      this.apiService.hideLoader();
    })
    // var streetName = event['feature'].getProperty('name')?event['feature'].getProperty('name'):'';
  }
  getAddressofPointonUpdate(selectedPin,index?){
    this.apiService.showLoader();
    console.log(selectedPin.Latitude+','+selectedPin.Longitude)
    var url = 'https://nominatim.openstreetmap.org/reverse?lat='+selectedPin.Latitude+'&lon='+selectedPin.Longitude+'&format=json';
    this.apiService.getByExternalUrl(url).subscribe((data: any) => {
      if(data && data.display_name){
        if(index){
          this.serviceList[index]['SiteAddress'] = data.display_name;
        }
      }
      // this.newServiceList.push(newProblem)
    this.apiService.hideLoader();
    }, (error) => {
      console.log(error);
      this.apiService.hideLoader();
    })
    // var streetName = event['feature'].getProperty('name')?event['feature'].getProperty('name'):'';
  }
  async deleteConfirmation(service) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Please confirm that you\'d like to delete this event:?: \n\n ' + service['Title']
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteItem(service);
      }
    });
  }
  deleteItem(problem){
    var url = 'siteProblem?orgId='+this.orgId+'&id='+problem.Id;
    this.apiService.deleteData(url).subscribe((data: any) => {
      this.getServicesData('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
      this.helper.openSnackBar("Event deleted successfully!", "OK")
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  //Portal dropdown change event
  getportalId(portalId) {
    console.log(portalId);
    this.selectedPortal = Number(portalId);
    this.getServicesData('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
  }
  async getUserDetails() {
    var username = this.apiService.getLocalStorage('username');
    var url = 'user?name='+username.toLowerCase()+'&page=0&size=10&orgId=' + this.orgId;
    this.apiService.getByUrl(url).subscribe((data: any) => {
      if(data.users[0]){
        this.lastVisitedPortal = Number(data.users[0].lastVisitedPortal);
        this.userData = data.users[0];
      }
      this.getOrgDetails();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  clearAllMarkers(){
    this.newServiceList=[];
    // this.drawingManager.setMap(null)
  }
  markerDragStart(event: MouseEvent, mIndex){
    console.log(this.newServiceList)
    console.log(event);
  }
  markerDragEnd(type:string, event: MouseEvent, mIndex){
    console.log(event);
    if(type && type === 'post'){
      this.newServiceList[mIndex].Latitude = event.coords.lat;
      this.newServiceList[mIndex].Longitude = event.coords.lng;
      this.getAddressofPoint(this.newServiceList[mIndex],mIndex)
      console.log(this.newServiceList)
    }
    if(type && type === 'patch'){
      this.serviceList[mIndex].Latitude = event.coords.lat;
      this.serviceList[mIndex].Longitude = event.coords.lng;
      this.getAddressofPointonUpdate(this.serviceList[mIndex],mIndex)
    }
  }
  isHaveEmptyField(event){
    var state = false;
    if(event && (event.Title === '' || event.ProblemType === '' || event.Details === ''
      || (event.Portal && event.Portal.length === 0))
    ){
      state = true;
    }
    return state
  }
  async saveEvent(event: any){
    var payload = {};
    payload = event;
    // payload.forEach((service) => {
      payload['StartDate'] = this.datepipe.transform(payload['StartDate'], 'yyyy-MM-dd HH:mm:00');
      payload['EndDate'] = this.datepipe.transform(payload['EndDate'], 'yyyy-MM-dd HH:mm:00');
      payload['Portal'].map(portal => {
        payload['PortalId'].push(portal.Id)
      })
      delete payload['Portal'];
    // })
    var url = 'siteProblem';
    await this.apiService.postNewdata(url, [payload]).subscribe(
      (res: any) => {
        console.log(res)
        this.newServiceList = [];
        this.helper.openSnackBar("Service event created successfully!", "OK")
        this.getServicesData('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async updateEvent(event: any){
    event.PortalId = [];
    event.StartDate = this.datepipe.transform(event.StartDate, 'yyyy-MM-dd HH:mm:00');
      event.EndDate = this.datepipe.transform(event.EndDate, 'yyyy-MM-dd HH:mm:00');
      event.Portal.map(portal => {
        event.PortalId.push(portal.Id)
      })
      delete event.Portal;
      delete event.CreatedBy;
      delete event.CreatedDate;
      delete event.LastModifiedBy;
      delete event.LastModifiedDate;
      delete event.pinIcon;
    var url = 'siteProblem?orgId='+this.orgId+'&id='+event['Id'];
    await this.apiService.updatedata(url, event).subscribe(
      (res: any) => {
        this.newServiceList = [];
        this.helper.openSnackBar("Service event updated successfully!", "OK")
        this.getServicesData('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  deleteSelectedService(sIndex){
    this.newServiceList.splice(sIndex, 1);
  }
  getEventsByProblemType(event){
    console.log(event);
    var url = 'siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal;
    if(event !== ''){
      url += '&type='+event
    }
    this.getServicesData(url)
  }
  editItem(service){
    service.PortalId = JSON.parse(service.PortalId)
    service.Portal = [];
    this.allPortals.forEach(portal => {
      if(service.PortalId.includes(portal.Id)){
        service.Portal.push(portal)
      }
    })
    if(this.newServiceList.length === 0){
      this.newServiceList.push(service)
    } else {
      this.helper.openSnackBar("Please save/update pending event & try again!", "OK")
    }
  }
  openInfoWindow() {
    // const infoWindow = this.infoWindowsView.toArray()[index];
    if(!this.infoWindowsView.isOpen || this.infoWindowsView.isOpen){
      this.infoWindowsView.open()  
    }
    this.infoWindowsView.open();
  }
  getSvg(getSvg){
    var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg);
    return svgData;
  }
  convertToDateTimeLocalFormat(dateTime: string): string {
    const date = new Date(dateTime);
    const year = date.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());

    return dateTime!== null?`${year}-${month}-${day}T${hours}:${minutes}`:null;
  }
  padZero(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
  async getproblemTypes() {
    var url = 'ssm?key=SiteProblemTypes';
    this.apiService.getByUrl(url).subscribe((data: any) => {
      this.ProblemTypes = data.value.split(',');
      this.getServicesData('siteProblem?orgId='+this.orgId+'&portalId='+this.selectedPortal);
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
}

