import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/utils/auth.service';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { ColorPickerComponent } from 'smart-webcomponents-angular/colorpicker';
import { HelperService } from 'src/app/api/helper.service';
import { FormControl } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material';
import { ColorEvent } from 'ngx-color';
import { colorConfig } from 'src/environments/color-configs';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {
  @ViewChild('colorpicker', { read: ColorPickerComponent, static: false }) colorpicker!: ColorPickerComponent;
  showPage = false;
  allPortals = [];
  domain = 'snowpaths';
  saveBg = 'rgb(241, 119, 46)';
  statusKeyList:any;
  statusRangeList = [];
  showColorPicker = false;
  selectedPortal: any;
  authResult: any;
  orgId = '';
  Hours:any;
  Message = '';
  selected = new FormControl(0);
  tabs = ['Key Instructions', 'White Roads'];
  allTabs = [];
  RoadStatusMessage = '';
  statusKeyApiType = 'post';
  MoreThanText = '';
  selectedColor = 'red';
  color = 'white';
  newStatus = {
    Hours: '',
    Message: '',
    Color: ''
  }
  tokenValue: any = {};
  isMaster: boolean = false

  constructor(private authService: AuthService, private apiService: ApiService,
    private helper: HelperService,private dialog : MatDialog) { }

  ngOnInit() {
    this.domain = this.apiService.getLocalStorage('domain');
      this.saveBg = colorConfig[this.domain]['status']['saveBackground'];
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
      if (session) {
        this.authResult = session;
        this.apiService.setAuthResult(session);
        this.tokenValue = {}
        this.isMaster = false;
        var storageRole = this.apiService.getLocalStorage('role');
        if(storageRole === 'Master'){
          this.isMaster = true;
          var masterTokens = JSON.parse(this.apiService.getLocalStorage('masterTokens'));
          var organizationId = window.location.href.split('manageOrg=').pop()
          var currentOrgToken = masterTokens[Number(organizationId)].split(' ')
          this.tokenValue = jwt_decode<JwtPayload>(currentOrgToken[1])
          this.apiService.managementToken = currentOrgToken[1];
          this.apiService.useManagementToken = true;
        }else {
          this.apiService.managementToken = session.getIdToken().getJwtToken();
          this.tokenValue =  jwt_decode<JwtPayload>(session.getIdToken().getJwtToken());
        }
        this.orgId = this.tokenValue['custom:organizationid'];
      }
    });
    this.getPortals();
    // this.username = this.apiService.getLocalStorage('username');
    // this.showPage = true;
  }
  async getPortals() {
    var url = 'portal?orgId=' + this.orgId;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.allPortals = res['portals'];
        this.selectedPortal = this.allPortals[0]['Id'];
        // this.getAlertMessage(this.selectedPortal);
        this.getStatusKey();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getStatusKey() {
    var url = 'statusKey?orgId=' + this.orgId + '&portalId=' + this.selectedPortal;
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.statusKeyList = res['statusKeys'];
        if (this.statusKeyList['Id']) {
          this.statusKeyApiType = 'patch';
          this.RoadStatusMessage = this.statusKeyList['RoadStatusMessage'];
          this.MoreThanText = this.statusKeyList['MoreThanText'];
          this.getStatusRange();
        }else{
          this.tabs.push('+');
        this.allTabs = this.tabs;
        // this.showPage=true;
        }
      }, (error) => {
        console.log(error);
        this.tabs.push('+');
        this.allTabs = this.tabs;
        // this.showPage=true;
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  saveStatusKey(){
    if(this.statusKeyApiType === 'post'){
      this.createStatusKey()
    }else{
      this.updateStatusKey()
    }
  }
  async createStatusKey() {
    var url = 'statusKey';
    var payload = {
      "OrganizationId": this.orgId,
      "MoreThanText": this.statusKeyList['MoreThanText'],
      "RoadStatusMessage": this.statusKeyList['RoadStatusMessage'],
      "SearchMessage": "Enter your address below or use the locaton button to see the streets in your neighborhood.",
      "PortalId": this.selectedPortal
    }
    await this.apiService.postNewdata(url,payload).subscribe(
      (res: any) => {
        console.log(res)
        this.statusKeyList = res;
        // this.getStatusRange();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async createStatusRange() {
    var url = 'statusRange?bulk=true';
    // var payload = {
    //   "OrganizationId": this.orgId,
    //   "PortalId": this.selectedPortal,
    //     "Hours": this.Hours,
    //     "Message": this.Message,
    //     "Color": this.selectedColor,
    //     "Order": 0,
    //     "StatusKeyId": this.statusKeyList['Id']
    // }
    await this.apiService.postNewdata(url,this.statusRangeList).subscribe(
      (res: any) => {
        console.log(res)
        // this.statusKeyList = res;
        this.getStatusKey();
        // this.getStatusRange();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async updateStatusKey() {
    var url = 'statusKey?statusKeyId=' + this.statusKeyList['Id'];
    await this.apiService.postNewdata(url,this.statusKeyList).subscribe(
      (res: any) => {
        console.log(res)
        this.statusKeyList = res;
        // this.getStatusRange();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async deleteData(getId) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Deletion',
        message: 'Are you sure you wish to remove this Status?: \n\n '
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.delStatusRange(getId)
      }
    });
  }
 async delStatusRange(getId){
    var id = 0;
    id = getId - 2;
    var url = 'statusRange?statusRangeId='+this.statusRangeList[id]['Id'];
    await this.apiService.deleteData(url).subscribe(
      (res: any) => {
        console.log(res)
        this.helper.openSnackBar("Deleted Success", "OK")
        this.getStatusKey();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async updateStatusRange(getId) {
    var id = 0;
    id = getId - 2;
    var url = 'statusRange?statusRangeId=' + this.statusRangeList[id]['Id'];
    var Payload = {
      "Hours": this.statusRangeList[id]['Hours'],
      "Message": this.statusRangeList[id]['Message'],
      "Color" : this.statusRangeList[id]['Color']
      };
    await this.apiService.updatedata(url,Payload).subscribe(
      (res: any) => {
        console.log(res)
        this.getStatusKey();
      }, (error) => {
        console.log(error);
        this.helper.openSnackBar(error, "OK")
      }
    )
  }
  async getStatusRange() {
    var tab = []
    var url = 'statusRange?orgId=' + this.orgId + '&portalId=' + this.selectedPortal + '&statusKeyId=' + this.statusKeyList['Id'];
    await this.apiService.getByUrl(url).subscribe(
      (res: any) => {
        console.log(res)
        this.statusRangeList = res['statusRanges'];
        res['statusRanges'].forEach(function callback(value, index) {
          console.log(value, index);
          let text = "Status Key " + (value['Order'] + 1)
          tab.push(text);
        });
        tab.push('+')
        console.log('tab items ' + this.tabs)
        this.allTabs = this.tabs.concat(tab);
        // this.RoadStatusMessage = this.statusKeyList['RoadStatusMessage'];
        // this.MoreThanText = this.statusKeyList['MoreThanText'];
      }, (error) => {
        console.log(error);
        this.tabs.push('+');
        this.allTabs = this.tabs;
        this.helper.openSnackBar(error, "OK")
      }
    )
    this.showColorPicker=true;
    this.showPage = true;
  }
  getColor(getIndex){
    var col = 'white'
    var ind = 0;
    ind = getIndex - 2;
    if(this.statusRangeList[ind]){
      return this.statusRangeList[ind]['Color']
    }else{
      return col
    }
  }
  checkField(getIndex){
    var ind = 0;
    ind = getIndex - 2;
    if(this.statusRangeList[ind]){
      return true
    }else{
      return false
    }
  }
  checkDelShow(getIndex){
    var ind = 0;
    ind = getIndex - 2;
    if(this.statusRangeList[ind] && ind !== 0){
      return true
    }else{
      return false
    }
  }
  getportalId(portalId) {
    this.showPage = false;
    console.log(portalId);
    this.selectedPortal = portalId;
    this.getStatusKey();
    // this.getAlertMessage(portalId);
  }
  onChangeColor(ind,event) {
    const detail = event.detail,
      oldValue = detail.oldValue,
      value = detail.value;
    this.selectedColor = value;
  }
  addTab(selectAfterAdding: boolean) {
    // this.tabs.push('+');

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }
  }
  handleChange(ind,$event: ColorEvent) {
    this.statusRangeList[ind]['Color'] = $event.color.hex;
  }
  handleNewColorChange($event: ColorEvent){
    this.newStatus['Color'] = $event.color.hex;
  }
  addNewStatus(){
    if(this.newStatus.Color !== '' && this.newStatus.Hours !== '' && this.newStatus.Message !== ''){
    this.newStatus['OrganizationId'] = this.orgId;
    this.newStatus['PortalId'] = this.selectedPortal;
    this.newStatus['StatusKeyId'] = this.statusKeyList['Id'];
    this.statusRangeList.push(this.newStatus);
    this.newStatus = {
      Hours:'',
      Message:'',
      Color:''
    }
    this.allTabs=[];
    var tab = []
    this.statusRangeList.forEach(function callback(value, index) {
          console.log(value, index);
          let text = "Status Key " + (index+1)
          tab.push(text);
        });
        tab.push('+')
        console.log('tab items ' + this.tabs)
        this.allTabs = this.tabs.concat(tab);
      }else{
        this.helper.openSnackBar("Hours, Description & Color fields mandatory","Ok")
      }
  }
}
