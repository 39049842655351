import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material';
import { ApiService } from 'src/app/api/api.service';
import { HelperService } from 'src/app/api/helper.service';
import { EditComponent } from 'src/app/shared/components/edit/edit.component';
import { FormControl } from '@angular/forms';
import { AgmInfoWindow, MapsAPILoader, MouseEvent } from '@agm/core';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { GeocodeService } from 'src/app/api/geocode.service';
import { colorConfig } from 'src/environments/color-configs';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  showCurrentLocation = false;
  // @ViewChild('search',{ static: true }) 
  @ViewChild('search', { static: true }) searchElementRef: ElementRef;
  @ViewChild('geoJsonInfoWindow', { static: false }) geoJsonInfoWindow: AgmInfoWindow;
  // public searchElementRef: ElementRef;
  public id: string;
  backendVersion: any;
  domain = 'snowpaths';
  alertClass = "showAlert"
  siteurl = '';
  iiion = 'C:\Users\Administrator\Desktop\Arrow 11 - 30x30.png'
  showMap = false;
  lat: any;
  deviceWidth:any;
  showView = true;
  publicAlert='';
  formClass = 'left';
  leftBgColor = '#f1772e';
  myLocationBg = '';
  mapClass = 'map-container-70';
  lng: any;
  publicLat: any;
  publicLng: any;
  mapType = 'roadmap'; //roadmap or satellite
  satellite = false;
  zoom = 13;
  helpUrl = '';
  intervalId:any;
  truckIntervalId:any;
  CurrentLat: any;
  CurrentLong: any;
  public color: ThemePalette = 'primary';
  url = '';
  status: any;
  streetPriorities: any = [];
  selected = new FormControl(0);
  myLocation = '';
  allTabs = [];
  showPage = false;
  welcomeMessage = true;
  address = '';
  welcomeLogo = '../../../../assets/logos/SnowPaths-logo.png';

  // 983293 magic number
  orgId: any;
  portalId: any;
  idlist: any;
  publicmapSettings: any = {};
  truckData: any;
  authResult: any;
  selectedMarker: any;
  showWelcome = false;
  public customStyle = [{
    "featureType": "poi",
    "stylers": [{
      visibility: "off",
    }]
  },];
  // address='';
  private geoCoder;
  priorityMapType: string = '';
  publicMapType: string = '';
  selectedgJsonStreetData: any;
  clickedLatLng: any;
  showPins: boolean = true;

  constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService, private ref: ChangeDetectorRef,
    private helper: HelperService, private dialog: MatDialog, private geocodeService: GeocodeService,
    private mapsAPILoader: MapsAPILoader, private ngZone: NgZone, @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.deviceWidth = window.innerWidth;
    const urlOrigin = window.location.origin;
    this.url = this.activatedRoute['_routerState'].snapshot.url;
    this.siteurl = urlOrigin + this.url;
    console.log(this.siteurl);
    let isAvailable = this.siteurl.includes("streetpaths");
    let isFleetpathAvailable = urlOrigin.includes("fleetpaths");
    if (isAvailable) {
      // this.domain = 'streetpaths';
      // this.welcomeLogo = '../../../../assets/logos/StreetPathsLogo.png'
      this._document.getElementById('appFavicon').setAttribute('href', 'streetpaths-favicon.png');
      this.titleService.setTitle('- streetpaths-4.0');
    }
    if(isFleetpathAvailable){
      // this.domain='fleetpaths';
      this._document.getElementById('appFavicon').setAttribute('href', 'fleetpaths-favicon.png');
      this.titleService.setTitle('- fleetpaths-4.0'); 
    }
    var isPublic = this.url.includes('Public')? true: false;
    this.id = this.url.includes('Public')?this.url.split('/Public/').pop():this.url.split('/public/').pop();
    this.idlist = this.id.split('/');
    this.orgId = 983293 ^ this.idlist[0];
    this.portalId = 983293 ^ this.idlist[1];
    this.getVersion();
  }
  handlePortalStyles(){
    this.updateThemeColor();
    this.welcomeLogo = '../../../../assets/logos/'+colorConfig[this.domain]['logoName']+'.png'
    this.leftBgColor = colorConfig[this.domain]['publicScreenBackgroud'];
    this.myLocationBg = colorConfig[this.domain]['myLocationbtnColor'];
  }
  updateThemeColor() {
    const metaTag = document.querySelector('meta[name="theme-color"]');
    if (metaTag) {
      metaTag.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    } else {
      this.createThemeColorMetaTag();
    }
  }

  createThemeColorMetaTag() {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'theme-color');
    meta.setAttribute('content', colorConfig[this.domain]['headerBackground']);
    document.head.appendChild(meta);
  }
  async getVersion() {
    var url = 'version';
    await this.apiService.getVersion(url).subscribe((data: any) => {
      this.backendVersion = data;
      this.apiService.addLocalStorage('Backend', data['Title'])
      this.apiService.addLocalStorage('BackendVersion', data['Version'])
      this.getMapDetails();
      // this.getPublicMapSettings();
    }, (error) => {
      console.log(error);
      this.getVersion
    })
  }
  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      if (this.searchElementRef && this.searchElementRef.nativeElement) {
        const service = new google.maps.places.PlacesService(
          this.searchElementRef.nativeElement
        );
        console.log(service)
      }
    });
  }
  async getMapDetails() {
    var url = 'organization?orgId=' + this.orgId + '&isPublic=true';
    this.apiService.getPublicMapDetails(url).subscribe((data: any) => {
      console.log(data);
      this.lat = data['siteSetting']['CenterLatitude'];
      this.lng = data['siteSetting']['CenterLongitude'];
      this.publicLat = data['siteSetting']['CenterLatitude'];
      this.publicLng = data['siteSetting']['CenterLongitude'];
      this.zoom = data['siteSetting']['zoomLevel'];
      this.helpUrl = data['siteSetting']['MunicipalityURL'];
      this.getPublicMapSettings();
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.getTruckMapData();
      if (this.truckIntervalId) {
        clearInterval(this.truckIntervalId);
      }
      this.intervalId = setInterval(() => {
        if(this.helper.isAuthenticated()){
          this.getPublicMapSettings();
        }
      }, environment.publicMapStreetRecall);
      this.truckIntervalId = setInterval(() => {
        if(this.helper.isAuthenticated()){
          this.getTruckMapData();
        }
      }, environment.publicMapTruckRecall);
      this.showMap = true;
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getPublicMapSettings() {
    this.allTabs = [];
    this.streetPriorities = [];
    this.publicmapSettings = Object.assign({});
    var url = 'publicMapSetting?orgId=' + this.orgId + '&portalId=' + this.portalId + '&domain=' + this.domain;
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      console.log(data)
      this.publicmapSettings = Object.assign<{}, any>({},data);
      this.domain = data['portalType'].toLowerCase();
      this.handlePortalStyles();
      this.publicAlert = this.deviceWidth > 640 ? data['alertMessage']:data['mobileAlertMessage']
      this.status = data['status'];
      this.allTabs.push('Status');
      this.publicMapType = data['publicMap'][0]?data['publicMap'][0].split('.').pop():'';
      if (data['priorityMap'] && data['priorityMap'] !== '') {
        this.priorityMapType = data['priorityMap'].split('.').pop();
        this.streetPriorities = data['streetPriorities'];
        this.allTabs.push('Priority');
      }
      if(this.welcomeMessage){
        this.showWelcomeMsg();
      }
      this.showMap = true;
      this.showPage = true;
      this.helper.getLatestUIVersion('ssmPublic?key=UiVersion');
      // this.getTruckMapData();
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  async getTruckMapData() {
    var url = 'publicMapTruck?orgId='+this.orgId+'&portalId='+this.portalId+'&domain='+this.domain;
    await this.apiService.getByPublicUrl(url).subscribe((data: any) => {
      console.log(data)
      this.truckData = data['trucks'];
      this.showMap = true;
      this.showPage = true;
      // if(this.welcomeMessage){
      //   this.showWelcomeMsg();
      // }
    }, (error) => {
      console.log(error);
      this.helper.openSnackBar(error, "OK")
    })
  }
  ngOndestroy() {
    this.apiService.addLocalStorage('showHeader', true)
  }
  showWelcomeMsg() {
    this.welcomeMessage=false;
    var title = 'Welcome!';
    const modalData = {};
    modalData['publicData'] = {};
    modalData['publicData'] = this.publicmapSettings;
    modalData['welcomeLogo'] = this.welcomeLogo;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: 'Welcome to ' + this.domain,
        data: modalData,
        orgId: this.orgId,
        type: 'welcome'
      },
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
      this.welcomeMessage = false;
    });
  }
  getClusterDetails(event) {
    console.log(event)
  }
  getIconData(getUrl: string) {
    var icon = {
      url: getUrl,
      scaledSize: {
        width: 25,
        height: 25
      }
    }
    return icon;
  }
  hideForm() {
    this.showView = !this.showView
    this.formClass = this.formClass === 'left' ? 'form-container-0' : 'left';
    this.mapClass = (this.formClass === 'left') ? 'map-container-70' : 'map-container-100';
  }
  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.CurrentLat = position.coords.latitude;
        this.CurrentLong = position.coords.longitude;
        this.zoom = 13;
        this.showCurrentLocation = true;
      }, function (e) {
        //Your error handling here
        console.log(e)
      }, {
        enableHighAccuracy: true
      });
    }
  }

  showLocation(event) {
    console.log(event)
    if (event.key === "Enter") {
      this.geocodeService.geocodeAddress(this.address)
        .subscribe((location: any) => {
          console.log(location)
          if (location.lat !== 0 && location.lng !== 0) {
            this.lat = location.lat;
            this.lng = location.lng;
            this.zoom = 15;
            this.ref.detectChanges();
          } else {
            this.helper.openSnackBar("Your address '" + this.address + "' is invalid", "Ok")
          }
        }
        );
    }
  }
  openHelp(){
    var title = 'Street Priority';
    const modalData = {};
    modalData['publicData'] = {};
    modalData['publicData'] = this.publicmapSettings;
    modalData['welcomeLogo'] = this.welcomeLogo;
    const confirmDialog = this.dialog.open(EditComponent, {
      data: {
        title: title,
        message: this.publicmapSettings.streetPriorityMessage,
        // 'Priority levels for servicing of local roadways is displayed in key under priority tab:',
        data: modalData,
        orgId: this.orgId,
        OrgName: "snow",
        helpUrl : this.helpUrl,
        type: 'help'
      },
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        console.log(result);
      }
    });
  }
  closeAlert(){
    this.alertClass = "hideAlert";
    document.getElementsByClassName('MobileAlertClass')[0]['style'].display = 'none';
  }
  getSvg(getSvg){
    var svgData = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(getSvg);
    return svgData;
  }
  showPublicMaps(){
    this.lat=this.publicLat;
    this.lng = this.publicLng
  }
  zoomChange(event: any){
    this.zoom=event;
    console.log(this.zoom)
  }
  routeLineStringStyle(feature: any) {
    // #b99885 brown
    const strokeColor = feature.getProperty('stroke');
    const strokeWeight = feature.getProperty('strokeWeight');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    return {
      strokeColor: strokeColor?strokeColor:'#b99885',
      strokeWeight: strokeWeight?strokeWeight:6,
      strokeOpacity: strokeOpacity && strokeColor !== '#FFFFFF'?strokeOpacity:strokeOpacity && strokeColor === '#FFFFFF'?0:0.7
    };
  }
  lineStringStyle(feature: any): any {
    const strokeColor = feature.getProperty('stroke');
    const highway = feature.getProperty('highway');
    const strokeOpacity = feature.getProperty('strokeOpacity');
    const strokeWeight = feature.getProperty('strokeWeight');
    
    // #b99885 brown
    return {
      strokeColor: strokeColor?strokeColor:'#1913E6',
      // highway === 'secondary'?'#b99885':highway === 'residential'?'#f1772e':strokeColor, // Change stroke color to blue
      strokeWeight: strokeWeight?strokeWeight:5,
      strokeOpacity: strokeOpacity?strokeOpacity:0.5
    };
  }
  dalaLayerClicked(event: MouseEvent){
    if(this.selectedgJsonStreetData && this.selectedgJsonStreetData.streetName){
      this.geoJsonInfoWindow.close();
    }
    console.log(event)
    this.clickedLatLng = {
      lat: event['latLng'].lat(),
      lng: event['latLng'].lng()
    };
    console.log(this.clickedLatLng.lat+','+this.clickedLatLng.lng)
    this.selectedgJsonStreetData = {};
    var streetName = event['feature'].getProperty('name')?event['feature'].getProperty('name'):'';
    var highway = event['feature'].getProperty('highway');
    var length = event['feature'].getProperty('length');
    var zoneName = event['feature'].getProperty('zoneName');
    if(streetName && streetName !== ''){
      this.selectedgJsonStreetData.streetName = streetName;
      this.changeDetectorRef.detectChanges();
      // Open the info window or perform any other actions with the latLng
      if(!this.geoJsonInfoWindow.isOpen || this.geoJsonInfoWindow.isOpen){
        this.geoJsonInfoWindow.open()  
      }
      this.geoJsonInfoWindow.open();
    }
  }
  getzIndexValue(kmzUrl: string){
    var zIndex = 0;
    if(kmzUrl.split('_').pop() && kmzUrl.split('_').pop().length === 5){
      var indexStr = kmzUrl.split('_').pop();
      zIndex = Number(indexStr[0])
    }
    return zIndex
  }
}
