import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthService } from 'src/utils/auth.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  serverUrl = environment.serverUrl;
  httpTokenOptions = {};
  httpOptions = {};
  httpDownloadOptions = {};
  httpDownloadZPLOptions = {};
  httpDownloadImageOptions = {};
  private showLongLoader: false;
  private allUsers: any;
  private authResult: any;
  private appVersion: any;
  isPendingResponse: boolean = false;
  domain: string;
  managementToken: any;
  useManagementToken: boolean = false;

  constructor(@Inject(DOCUMENT) private _document: HTMLDocument,private http: HttpClient, private router: Router,  private authService: AuthService) {
    this.httpOptions = { headers: new HttpHeaders({ Authorization: 'Bearer ' + this.managementToken }) };
    this.httpDownloadOptions = { responseType: 'blob', headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.managementToken }) };
    this.httpDownloadZPLOptions = { responseType: 'any', headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.managementToken }) };
    this.httpDownloadImageOptions = { responseType: 'blob', headers: new HttpHeaders({ 'Content-Type': 'image/jpeg', Authorization: 'Bearer ' + this.managementToken }) };
    // this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
    //   this.authResult= session;
    // });
  }

  setAuthResult(data){
    this.authResult = data;
  }
  setAppVersion(data){
    this.appVersion = data;
  }
  getAppVersion(data){
    return this.appVersion
  }
  
  setAppFavicon(id: string, basepath: string, icon: string){
    this._document.getElementById('appFavicon').setAttribute('href', '../../../tholos.png');
 }

  addLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  removeLocalStorage(key) {
    return localStorage.removeItem(key);
  }

  clearLocalStorage() {
    return localStorage.clear();
  }

  
  getHttpDownloadOption() {

    const httpDownloadPdfOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/pdf', Authorization: 'Bearer ' + this.managementToken }) };
    return httpDownloadPdfOptions;
  }
  getHttpDownloadBlobOption() {

    const httpDownloadBlobOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + this.managementToken }) };
    return httpDownloadBlobOptions;
  }
  getHttpMultipartOption() {

    const httpDownloadPdfOptions = { headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + this.managementToken }) };
    return httpDownloadPdfOptions;
  }

  handleError(error) {
    let errorMessage = '';
    if (!error.error.type) {
      // Get client-side error
      errorMessage = error.error;
    } else if(error.error.type) {
      errorMessage = error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    if(error.status === 0){
      errorMessage = "Connection lost retrying";
    }
    if (errorMessage === 'Authentication failed. Session expired') {
      setTimeout(() => {
        localStorage.clear();
        console.log('Authentication failed. Session expired & Local storage cleared.');
    }, 2000);
    }
    if (errorMessage === 'Exception occured while creating token using RSATokenVerifier Failed to parse JWT') {
      window.location.reload();
    }
    // window.alert(errorMessage);
    // this.hideLoader();
    // this.showLongLoader=false;
    // this.hideLoader();
    return throwError(errorMessage);
  }

  showLoader() {
    (document.querySelector('#loader') as HTMLElement).style.display = 'block';
  }
  hideLoader() {
    (document.querySelector('#loader') as HTMLElement).style.display = 'none';
  }
  postNewdata(url, data) {
    this.showLoader();
    const httpTokenOptions = this.getHttpOptions();
    return this.http.post(this.serverUrl + url, data, httpTokenOptions).pipe(
      finalize(() => {
          this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }
  postNewPublicdata(url, data) {
    this.showLoader();
    // const httpTokenOptions = this.getHttpOptions();
    const httpTokenOptions = {headers: new HttpHeaders({Authorization: 'Bearer '})}
    return this.http.post(this.serverUrl + url, data, httpTokenOptions).pipe(
      finalize(() => {
          this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }
  postNewdataWithQueryParams(url, data) {
    this.showLoader();
    const httpTokenOptions = {
      headers: new HttpHeaders({
        'Content-type': 'application/json',
        'Authorization': 'Bearer ' + this.managementToken
      })
    }
    return this.http.post(this.serverUrl + url, data,httpTokenOptions).pipe(
      finalize(() => {
          this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }
  postNewReportdata(url, data, loader?) {
    // if(loader){
    //   this.isPendingResponse = true;
    // }
    this.showLoader();
    const httpTokenOptions = this.getHttpOptions();
    return this.http.post(this.serverUrl + url, data, httpTokenOptions).pipe(
      finalize(() => {
        this.showLongLoader = loader;
        if(this.showLongLoader === false){
          this.hideLoader();
        }
      }),
      catchError(this.handleError)
    );
  }
  updatedata(url, data) {
    this.showLoader();
    const httpTokenOptions = this.getHttpOptions();
    return this.http.patch(this.serverUrl + url, data, httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }
  async getUsers() {
    this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
     const queryParam = 'accessToken=' + session.getAccessToken().getJwtToken();
     this.http.get('https://x98tupal3l.execute-api.us-east-1.amazonaws.com/dev/user', {
       headers: new HttpHeaders({Authorization: 'Bearer ' +this.managementToken})
     })
       .subscribe(
         (result: any) => {
           console.log('Got the response: ' + result);
           this.allUsers = result;
           return result;
         },
         (error: any) => {
           console.log('Got an error: ' + error);
           return null;
         }
       );
   });
   }

   getUser(url) {
    // this.showLoader();
    return this.http.get(this.serverUrl+url, this.getHttpOptions()).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getMapDetails(url) {
    // this.showLoader();
    return this.http.get(this.serverUrl+url, this.getHttpOptions()).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getPublicMapDetails(url) {
    // this.showLoader();
    const httpTokenOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer '
      })
    }
    return this.http.get(this.serverUrl+url, httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getByUrl(url,loader?) {
    this.showLongLoader = loader;
    this.showLoader();
    return this.http.get(this.serverUrl+url,this.getHttpOptions()).pipe(
      finalize(() => {
        if(loader){
        this.showLongLoader = loader;
        if(this.showLongLoader === false){
          this.hideLoader();
        }
      } else {
        this.hideLoader();
      }
      }),
      catchError(this.handleError)
      );
  }
  getByPublicUrl(url) {
    const httpTokenOptions = {headers: new HttpHeaders({Authorization: 'Bearer '})}
    return this.http.get(this.serverUrl+url,httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getVersion(url) {
    const httpTokenOptions = {headers: new HttpHeaders({Authorization: 'Bearer '})}
    return this.http.get(this.serverUrl+url,httpTokenOptions).pipe(
      finalize(() => {
        // this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getByExternalUrl(url) {
    const httpTokenOptions = {headers: new HttpHeaders({Authorization: 'Bearer '})}
    return this.http.get(url).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
      );
  }
  getHttpOptions() {
    var role = this.getLocalStorage('role');
    // if(role !== 'Master'){
    if(this.authResult){
      var apiToken = (this.useManagementToken && this.managementToken)?this.managementToken:this.authResult.getIdToken().getJwtToken();
      const httpTokenOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + apiToken
        })
      }
      return httpTokenOptions;
    }else {
      this.authService.getAuthenticatedUser().getSession((err: any, session: any) => {
        var apiToken = (this.useManagementToken && this.managementToken)?this.managementToken:this.authResult.getIdToken().getJwtToken();
        const httpTokenOptions = {
          headers: new HttpHeaders({
            Authorization: 'Bearer ' + apiToken
          })
        }
        return httpTokenOptions;
      })
    }
  // } else {
    // var orgToken = JSON.parse(this.getLocalStorage('masterTokens'))
    // const httpTokenOptions = {
      // headers: new HttpHeaders({
        // Authorization: orgToken['13']
      // })
    // }
    // return httpTokenOptions;
  // }

  }
  deleteData(url) {
    this.showLoader();
    const httpTokenOptions = this.getHttpOptions();
    return this.http.delete(this.serverUrl + url, httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }

  login(username, password) {
    this.showLoader();
    const body = {};
    const options = {};
    const url = this.serverUrl + '/auth/login?' + 'username=' + username + '&password=' + password;
    return this.http.post(url, body, options).pipe(
      finalize(() => this.hideLoader()),
      catchError(this.handleError)
    );

  }

  logout(token) {
    this.showLoader();
    const url = this.serverUrl + '/auth/logout';
    const httpTokenOptions = this.getHttpOptions();
    return this.http.get(url, httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
    );
  }

  searchByProperty(name, property) {
    const url = this.serverUrl + 'search?table=' + name + '&property=' + property + '&sort=updatedDate DESC';
    const httpTokenOptions = this.getHttpOptions();
    this.showLoader();
    return this.http.get(url, httpTokenOptions).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      catchError(this.handleError)
    );

  }
  getSearchwithPagination(searchurlurl, searchParams, sortParams, page, rows) {
    this.showLoader();
    let url = this.serverUrl + searchurlurl;
    if (searchParams !== '') {
      // url += '&q=' + searchParams;
      url += searchParams;
    }
    if (sortParams !== '') {
      url += '&' + sortParams;
    }
    if (page != undefined && rows != undefined) {
      url += '&page=' + page + '&size=' + rows;
    } 
    return this.http.get(url, this.getHttpOptions()).pipe(
      finalize(() => {
        this.hideLoader();
      }),
      // this.hideLoader()
      catchError(this.handleError)
    );
  }
  downloadPdf(pdfUrl: string): Observable<Blob> {
    return this.http.get(pdfUrl, { responseType: 'blob' });
  }
}
